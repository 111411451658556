import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Stack from '@mui/material/Stack';
import DeviceSelect, { DeviceSelectProps } from '../components/DeviceSelect';
import Typography from '@mui/material/Typography';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import * as dateFns from 'date-fns';
import { ja } from 'date-fns/locale';

import TemperatureChart from '../components/Chart/TemperatureChart';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Button } from '@mui/material';
import { DeviceContext } from '../contexts/device';

// チャートのタイプを管理するenumです
type ChartType = 'temperature';

const now = new Date();

interface IProps {
  targetDeviceId?: string;
}

const Dashboard: React.FC<IProps> = (param) => {
  const { getFirst } = React.useContext(DeviceContext);
  // URLのクエリパラメータから検索の範囲を取得します。キーはfrom,toとし、形式はISO-8601に準拠します。
  const [searchParams, setSearchParams] = useSearchParams({
    // 以下はクエリパラメータに日付範囲が含まれない場合のデフォルト値を指定しています。
    from: dateFns.subDays(now, 1).toISOString(),
    to: now.toISOString(),
    chart: 'temperature' as ChartType,
    deviceId: param.targetDeviceId ?? getFirst()?.deviceId ?? '',
  });

  // 上記でデフォルト値を入れているので!を付与しています。
  const from = new Date(searchParams.get('from')!);
  const to = new Date(searchParams.get('to')!);
  const chart = searchParams.get('chart') as ChartType;
  const deviceId = searchParams.get('deviceId') as string;

  // stateを宣言します。それぞれ値が変更されるとComponentが再描画されます。
  const [range, setRange] = React.useState({
    // DateRangePickerで選択された範囲を一時的に格納します
    startDate: from,
    endDate: to,
  });

  // クエリパラメータを変更することで上記のuseEffectを発火させ、チャートを更新します。
  const reloadChart = () => {
    const { startDate, endDate } = range;
    setSearchParams({
      from: startDate.toISOString(),
      to: endDate.toISOString(),
      chart,
      deviceId,
    });
  };

  const changeChartType: RadioGroupProps['onChange'] = (e) => {
    setSearchParams({
      from: from.toISOString(),
      to: to.toISOString(),
      chart: e.target.value,
      deviceId,
    });
  };

  const changeDevice: DeviceSelectProps['onChange'] = (deviceId) => {
    setSearchParams({
      from: from.toISOString(),
      to: to.toISOString(),
      chart: chart,
      deviceId,
    });
  };

  const updateRange = (start: Date | null, end: Date | null) => {
    setRange({
      startDate: start ?? range.startDate,
      endDate: end ?? range.endDate,
    });
  };
  // URLのクエリパラメータから表示すべきチャートの種類を特定します
  const Chart = React.useMemo(() => {
    return <TemperatureChart from={from} to={to} deviceId={deviceId} chartType={chart} />;
  }, [from.toISOString(), to.toISOString(), deviceId, chart]);

  return (
    <Container>
      <Typography variant="h6" gutterBottom component="div" mb={2}>
        Time Series
      </Typography>
      <Stack spacing={4}>
        <Paper>
          <Stack direction="column" spacing={2} padding={2} px={8} justifyContent="space-between">
            {param.targetDeviceId == null && (
              <Stack flex={1}>
                <DeviceSelect value={deviceId} onChange={changeDevice} />
              </Stack>
            )}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                <DateTimePicker
                  label="Start"
                  renderInput={(params) => <TextField {...params} />}
                  value={range.startDate}
                  onChange={(newValue) => updateRange(newValue!, null)}
                  minDate={dateFns.subMonths(new Date(), 1)}
                  maxDate={new Date()}
                />
                <DateTimePicker
                  label="End"
                  renderInput={(params) => <TextField {...params} />}
                  value={range.endDate}
                  onChange={(newValue) => updateRange(null, newValue)}
                  minDate={dateFns.subMonths(new Date(), 1)}
                  maxDate={new Date()}
                />
                <Button variant="contained" onClick={reloadChart}>
                  Reload
                </Button>
              </Stack>
            </LocalizationProvider>
          </Stack>
        </Paper>
        <Paper>
          <Stack flex={1} alignItems="center" mt={1}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="types"
                defaultValue={chart}
                name="energy_consumption-group"
                onChange={changeChartType}
              >
                <FormControlLabel value="temperature" control={<Radio />} label="Temperature" />
                <FormControlLabel value="gas" control={<Radio />} label="Gas" />
                <FormControlLabel value="water" control={<Radio />} label="Water" />
              </RadioGroup>
            </FormControl>
          </Stack>
          {Chart}
        </Paper>
      </Stack>
    </Container>
  );
};

export default Dashboard;
