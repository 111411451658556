import { API, Auth } from 'aws-amplify';
import * as Api from '../shared/types/api';
import { API_URL_BASE } from '../consts/env';

export * as Api from '../shared/types/api';

API.configure({
  endpoints: [
    {
      name: 'API',
      endpoint: API_URL_BASE,
      custom_header: async () => {
        return {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        };
      },
    },
  ],
});

export const getUsers = async (): Promise<Api.User.GetResponse> => {
  const res = await API.get('API', `/users`, {});
  return res;
};

export const createUser = async (props: Api.User.PostRequest): Promise<Api.User.PostResponse> => {
  const res = await API.post('API', '/users', {
    body: props,
  });
  return res;
};

export const getUser = async (props: Api.User.FindRequest): Promise<Api.User.FindResponse> => {
  const res = await API.get('API', `/users/${encodeURI(props.username)}`, {});
  return res;
};

export const updateUser = async (props: Api.User.PatchRequest): Promise<Api.User.PatchResponse> => {
  const res = await API.patch('API', `/users/${encodeURI(props.username!)}`, {
    body: props,
  });
  return res;
};

/**
 * devices API client
 */
export const getDevices = async (): Promise<Api.Device.GetResponse> => {
  const res = await API.get('API', `/devices`, {});
  return res;
};

export const createDevice = async (props: Api.Device.PostRequest): Promise<Api.Device.PostResponse> => {
  const res = await API.post('API', '/devices', {
    body: props,
  });
  return res;
};

export const findDevice = async (props: Api.Device.FindRequest): Promise<Api.Device.FindResponse> => {
  const res = await API.get('API', `/devices/${props.deviceId}`, {});
  return res;
};

export const updateDevice = async (props: Api.Device.PatchRequest): Promise<Api.Device.PatchResponse> => {
  const res = await API.patch('API', `/devices/${props.deviceId}`, {
    body: props,
  });
  return res;
};

export const updateDeviceLocalSettings = async (
  props: Api.Device.PatchLocalRequest
): Promise<Api.Device.PatchResponse> => {
  const res = await API.patch('API', `/devices/${props.deviceId}?detail=true`, {
    body: props,
  });
  return res;
};

/**
 * companies API client
 */
export const getCompanies = async (): Promise<Api.Company.GetResponse> => {
  const res = await API.get('API', `/companies`, {});
  return res;
};

export const createCompany = async (props: Api.Company.PostRequest): Promise<Api.Company.PostResponse> => {
  const res = await API.post('API', '/companies', {
    body: props,
  });
  return res;
};

export const findCompany = async (props: Api.Company.FindRequest): Promise<Api.Company.FindResponse> => {
  const res = await API.get('API', `/companies/${props.companyId}`, {});
  return res;
};

export const updateCompany = async (props: Api.Company.PatchRequest): Promise<Api.Company.PatchResponse> => {
  const res = await API.patch('API', `/companies/${props.companyId}`, {
    body: props,
  });
  return res;
};

/**
 * metrics API client
 */
type ApiMetricsGetRequest = Api.Metrics.GetRequest;

const getMetrics = async <T>(props: Api.Metrics.GetRequest): Promise<T> => {
  const { deviceId, from, to, type } = props;
  const res = await API.get('API', `/metrics`, {
    queryStringParameters: {
      deviceId,
      type,
      from,
      to,
    },
  });
  return res;
};

export const getChartData = async (props: ApiMetricsGetRequest): Promise<Api.Metrics.GetChartDataResponse> => {
  const { deviceId, from, to, type } = props;
  const res = await getMetrics<Api.Metrics.GetChartDataResponse>({ deviceId, from, to, type });
  return res;
};

export const getAllData = async (props: ApiMetricsGetRequest): Promise<Api.Metrics.GetChartDataResponse> => {
  const { deviceId, from, to, type } = props;
  const res = await getMetrics<Api.Metrics.GetChartDataResponse>({ deviceId, from, to, type });
  return res;
};
