import React from 'react';

import { Api, getDevices } from '../libs/api';

type Device = Api.Device.Type;

type DeviceContextValueType = {
  devices: Map<string, Device>;
  loading: boolean;
};

type DeviceContextDispatchType = {
  getFirst: () => Device | null;
  getDeviceById: (deviceId: string) => Device | null;
  refresh: () => Promise<void>;
};

/**
 * Device情報はページ横断的に利用されるのでアプリを立ち上げた時に取得してメモリ上に保存します
 */
export const DeviceContext = React.createContext<DeviceContextValueType & DeviceContextDispatchType>({
  devices: new Map(),
  loading: true,
  getFirst: () => null,
  getDeviceById: (deviceId: string) => null,
  refresh: () => Promise.resolve(),
});

export const DeviceProvider: React.FC = (props) => {
  const { children } = props;

  const [value, setValue] = React.useState<DeviceContextValueType>({
    devices: new Map(),
    loading: true,
  });

  // Deviceを追加した時にrefreshする必要があるのでrefresh関数を用意しておきます
  const refresh = () =>
    getDevices().then((data) => {
      const deviceMap = data.reduce((acc, cur) => {
        acc.set(cur.deviceId, cur);
        return acc;
      }, new Map());
      setValue({
        devices: deviceMap,
        loading: false,
      });
    });

  const getFirst = (): Device | null => {
    const { devices } = value;
    if (devices.size === 0) return null;
    return devices.values().next().value;
  };

  const getDeviceById = (deviceId: string): Device | null => {
    const { devices } = value;
    if (devices.size === 0) return null;
    return devices.get(deviceId) != null ? devices.get(deviceId)! : null;
  };

  React.useEffect(() => {
    refresh();
  }, []);

  return (
    <DeviceContext.Provider
      value={{
        ...value,
        refresh,
        getFirst,
        getDeviceById,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
