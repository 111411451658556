import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { Api, getUser } from '../libs/api';

type User = Api.User.Type;

type UserContextValueType = {
  user: User | null;
  loading: boolean;
};

/**
 * User情報はページ横断的に利用されるのでアプリを立ち上げた時に取得してメモリ上に保存します
 */
export const UserContext = React.createContext<UserContextValueType>({
  user: null,
  loading: true,
});

export const UserProvider: React.FC = (props) => {
  const { children } = props;
  const { user } = useAuthenticator();
  const { username } = user;

  const [value, setValue] = React.useState<UserContextValueType>({
    user: null,
    loading: true,
  });

  React.useEffect(() => {
    if (!username) return;
    getUser({
      username,
    }).then((data) => {
      setValue({
        user: data,
        loading: false,
      });
    });
  }, [username]);

  return (
    <UserContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
