import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { createDevice, Api } from '../../../libs/api';
import { DeviceContext } from '../../../contexts/device';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

import CompanySelect from '../../../components/CompanySelect';
import Link from '@mui/material/Link';
import Checkbox from '@mui/material/Checkbox';

type FormType = Api.Device.PostRequest;
const Page: React.FC = () => {
  const navigate = useNavigate();
  const { refresh } = React.useContext(DeviceContext);
  const [error, setError] = React.useState<string>();
  const [info, setInfo] = React.useState<string>();
  const [checked, setChecked] = React.useState(false);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormType>({
    mode: 'onBlur',
    defaultValues: {
      companyId: '',
      deviceId: '',
      name: '',
    },
  });

  const onSubmit = (data: FormType) =>
    createDevice(data)
      .then((resp) => {
        refresh().then(() => {
          setInfo(JSON.stringify(resp));
        });
      })
      .catch((e) => {
        setError(e.message);
      });

  return (
    <Box padding={2} component={Paper}>
      <Typography variant="h6" gutterBottom component="div" mb={2}>
        Add new device
      </Typography>
      {!info && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <Controller
              name="deviceId"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label="Device ID (MQTT Client ID)"
                  helperText="set device ID. The value should be the same as MQTT client-id."
                />
              )}
            />
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label="Serial Number"
                  helperText="Enter the device's Serial Number like CH-00000."
                />
              )}
            />
            <Controller
              name="companyId"
              control={control}
              render={({ field }) => (
                <FormControl required>
                  <CompanySelect controlRenderProps={field} inputLabel="Location" />
                  <FormHelperText>Choose a location where the device would be installed.</FormHelperText>
                </FormControl>
              )}
            />
            <Stack direction="row">
              <Button type="submit" variant="contained" loading={isSubmitting}>
                Create
              </Button>
            </Stack>
          </Stack>
        </form>
      )}
      {error && (
        <Stack py={2}>
          <Alert severity="error">
            <AlertTitle>Server Error</AlertTitle>
            {error}
          </Alert>
        </Stack>
      )}
      {info && (
        <Stack py={2}>
          <Alert severity="info">
            <AlertTitle>Provisioning Information</AlertTitle>
            <Typography variant="body2" marginBottom={2}>
              1. download certificate, key and CA cert. (For security reason, you can't download them again.)
              <br />
              2. copy the endpoint and port information. <br />
              3. configure your BMS MQTT settings with those information. <br />
            </Typography>
          </Alert>
          <Stack spacing={1} margin={3}>
            <Typography variant="body2">
              Endpoint:<strong>{JSON.parse(info).endpointUrl}</strong>{' '}
            </Typography>
            <Typography variant="body2">
              Port: <strong>8883</strong>
            </Typography>
            <Typography variant="body2">
              Certificate:
              <Link href={JSON.parse(info).certUrl}>
                <strong>download</strong>
              </Link>
            </Typography>
            <Typography variant="body2">
              Key:
              <Link href={JSON.parse(info).keyUrl}>
                <strong>download</strong>
              </Link>
            </Typography>
            <Typography variant="body2">
              CA Cert (please click the link and save the page.):
              <Link target="_blank" href="https://www.amazontrust.com/repository/AmazonRootCA1.pem">
                <strong>link</strong>
              </Link>
            </Typography>
          </Stack>
          <Stack direction="row">
            <FormControlLabel
              label="I downloaded the certificate and key."
              control={
                <Checkbox
                  checked={checked}
                  onChange={(event) => {
                    setChecked(event.target.checked);
                  }}
                />
              }
            />
            <Button
              variant="contained"
              disabled={!checked}
              onClick={() => {
                navigate('/admin/devices', { replace: true });
              }}
            >
              Finish
            </Button>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default Page;
