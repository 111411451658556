import React from 'react';
import { useParams } from 'react-router-dom';

import { Api } from '../../../libs/api';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { DeviceContext } from '../../../contexts/device';
import { CompanyContext } from '../../../contexts/company';
import DashboardPage from '../../../pages/dashboard';
import DeviceStatusTable from '../../../components/Chart/DeviceStatusTable';

const Page: React.FC = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const [deviceName, setDeviceName] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');

  const { getDeviceById } = React.useContext(DeviceContext);
  const { companies } = React.useContext(CompanyContext);

  type Item = Api.Company.Type;
  const items: Item[] = [];
  for (let item of companies.values()) {
    items.push(item);
  }

  React.useEffect(() => {
    if (!deviceId) return;
    const device = getDeviceById(deviceId!);
    if (device) {
      setDeviceName(device!.name! ? device!.name! : '');
    }
    const company = items[items.findIndex((item) => item.companyId === device!.companyId)];
    if (company) {
      setCompanyName(company.name);
    }
  }, [deviceId]);

  return (
    <Box padding={2} component={Paper}>
      <Typography variant="h5" gutterBottom component="div" mb={2}>
        {companyName} - {deviceName} ({deviceId})
      </Typography>
      <DashboardPage targetDeviceId={deviceId} />
      <Typography variant="h6" gutterBottom component="div" mb={2}>
        Current status
      </Typography>
      <DeviceStatusTable deviceId={deviceId!}></DeviceStatusTable>
    </Box>
  );
};

export default Page;
