import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import { getAllData, Api } from '../../libs/api';
import { CircuitTables } from './CircuitTables';

interface IProps {
  deviceId: string;
}

const DeviceStatusTable: React.FC<IProps> = (props) => {
  const { deviceId } = props;
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<Api.Metrics.GetChartDataResponse>([]);

  React.useEffect(() => {
    setLoading(true);
    const now = new Date();
    const threeMinAgo = new Date(now.getTime() - 3 * 60 * 1000);
    getAllData({
      deviceId,
      from: threeMinAgo.toISOString(),
      to: now.toISOString(),
      type: 'all',
    })
      .then((data) => {
        console.log(data[0]);
        let source = data[0] as Array<any>;
        if (source) {
          setData(source.filter((item) => item.value != null));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [deviceId]);

  if (loading) {
    return (
      <Stack alignItems="center" padding={4}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <TableContainer component={Paper}>
      <CircuitTables data={data}></CircuitTables>
      <Table sx={{ minWidth: 250, maxWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.key}
              </TableCell>
              <TableCell align="right">{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default DeviceStatusTable;
