import React from 'react';

import { ControllerRenderProps } from 'react-hook-form';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { CompanyContext } from '../contexts/company';

import { Api } from '../libs/api';

type Item = Api.Company.Type;

type Props = {
  required?: boolean;
  multiple?: boolean;
  // react-hook-formで制御する際に必要です
  controlRenderProps?: ControllerRenderProps<any, any>;
  formHelperText?: string;
  inputLabel?: string;
};
const Component: React.FC<Props> = (props) => {
  const { required, multiple, controlRenderProps, inputLabel, formHelperText } = props;
  const { companies, loading } = React.useContext(CompanyContext);
  const items: Item[] = [];
  for (let item of companies.values()) {
    items.push(item);
  }
  return (
    <FormControl required={required}>
      {inputLabel && <InputLabel id="user-companies-label">{inputLabel}</InputLabel>}
      <Select
        {...controlRenderProps}
        defaultValue={multiple ? [] : ''}
        multiple={multiple}
        disabled={loading}
        labelId="user-companies-label"
        label={inputLabel}
      >
        {items.map((company) => (
          <MenuItem key={company.companyId} value={company.companyId}>
            {company.name}
          </MenuItem>
        ))}
      </Select>
      {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
    </FormControl>
  );
};

export default Component;
