import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useAuthenticator } from '@aws-amplify/ui-react';

const ListItemLink: React.FC<{
  icon?: React.ReactNode;
  primary: string;
  to: string;
}> = (props) => {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any>(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <ListItem button component={renderLink}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
    </ListItem>
  );
};

export const MainNavItems: React.FC = () => (
  <div>
    <ListItemLink to="/" icon={<DashboardIcon />} primary="Dashboard" />
  </div>
);

export const AdminNavItems: React.FC = () => (
  <div>
    <ListItemLink to="/admin/users" primary="Users" />
    <ListItemLink to="/admin/companies" primary="Locations" />
    <ListItemLink to="/admin/devices" primary="Devices" />
  </div>
);

export const SignOutItem: React.FC = () => {
  const { signOut } = useAuthenticator();
  return (
    <div>
      <ListItem button onClick={signOut}>
        <ListItemText primary="Sign out" />
      </ListItem>
    </div>
  );
};
