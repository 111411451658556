import * as React from 'react';
import { CircuitTable, lawDataKeyValue } from './CircuitTable';
import { Stack } from '@mui/material';

interface IProps {
  data: lawDataKeyValue[];
}

export const CircuitTables: React.FC<IProps> = (props) => {
  const data: lawDataKeyValue[] = props.data;
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }}>
      <CircuitTable data={data} channel={1} />
      <CircuitTable data={data} channel={2} />
      <CircuitTable data={data} channel={3} />
    </Stack>
  );
};
