import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export type lawDataKeyValue = {
  key: string;
  value: string;
};

const PumpOperationMode = {
  '1': 'Auto',
  '2': 'xx',
  '4': 'xx',
} as const;
interface IProps {
  data: lawDataKeyValue[];
  channel: number;
}

export const CircuitTable: React.FC<IProps> = (props) => {
  console.log(props);
  const data: lawDataKeyValue[] = props.data;
  const channel: number = props.channel;
  function findValue(key: string) {
    const val = data.find((item) => {
      return item.key === key;
    });
    return val?.value ?? '';
  }
  function getPumpOperationState(value: keyof typeof PumpOperationMode): string {
    return PumpOperationMode[value];
  }
  function checkActive(channel: number, pump: number): React.ReactNode {
    if (Number(findValue(`circuit${channel}overview__Active_Pump`)) === pump) {
      return <strong>Active</strong>;
    } else {
      return '';
    }
  }
  return (
    <Container>
      <Stack flex={1} alignItems="center" mt={1} spacing={2}>
        <Typography variant="h6" gutterBottom component="div" mb={2}>
          Circuit {channel}
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 150 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Pump</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Operation hours</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="OperationMode" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Pump1
                </TableCell>
                <TableCell align="right">{checkActive(channel, 1)}</TableCell>
                <TableCell align="right">
                  {findValue(`circuit${channel}overview__RunHours1`)}
                  {findValue(`circuit${channel}overview__RunHours`)}
                </TableCell>
              </TableRow>
              <TableRow key="FlowRate" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Pump2
                </TableCell>
                <TableCell align="right"> {checkActive(channel, 2)}</TableCell>
                <TableCell align="right">{findValue(`circuit${channel}overview__RunHours2`)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell align="right">value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="OperationMode" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  OperationMode
                </TableCell>
                <TableCell align="right">
                  {getPumpOperationState(
                    findValue(`Pump_control_Circuit${channel}_Pumps`) as keyof typeof PumpOperationMode
                  )}
                </TableCell>
              </TableRow>
              <TableRow key="FlowRate" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Flow Rate
                </TableCell>
                <TableCell align="right">{findValue(`circuit${channel}overview__FlowRate`)} L/min</TableCell>
              </TableRow>
              <TableRow key="Pressure1" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Pressure 1
                </TableCell>
                <TableCell align="right">{findValue(`circuit${channel}overview__Pressure1`)} kPa</TableCell>
              </TableRow>
              <TableRow key="Pressure1Abnormal" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Pressure 1 abnormal
                </TableCell>
                <TableCell align="right">{findValue(`circuit${channel}overview__Pressure1B`)}</TableCell>
              </TableRow>
              <TableRow key="Pressure2" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Pressure 2
                </TableCell>
                <TableCell align="right">{findValue(`circuit${channel}overview__Pressure2`)} kPa</TableCell>
              </TableRow>
              <TableRow key="Pressure2Abnormal" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  Pressure 2 abnormal
                </TableCell>
                <TableCell align="right">{findValue(`circuit${channel}overview__Pressure2B`)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Container>
  );
};
