import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { updateDevice, findDevice, Api } from '../../../libs/api';
import { DeviceContext } from '../../../contexts/device';
import { CompanyContext } from '../../../contexts/company';
import CompanySelect from '../../../components/CompanySelect';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

type FormType = Api.Device.PatchRequest;
type Item = Api.Company.Type;
let deviceReported: Api.Device.FindResponse;

const Page: React.FC = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const navigate = useNavigate();
  const { refresh } = React.useContext(DeviceContext);
  const { companies } = React.useContext(CompanyContext);
  const [loading, setLoading] = React.useState(true);
  const [deviceName, setDeviceName] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [error, setError] = React.useState<string>();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormType>({
    mode: 'onBlur',
    defaultValues: {
      companyId: '',
    },
  });
  const items: Item[] = [];
  for (let item of companies.values()) {
    items.push(item);
  }

  React.useEffect(() => {
    if (!deviceId) return;
    findDevice({ deviceId })
      .then((device) => {
        reset({
          deviceId: device.deviceId,
          name: device.name,
          companyId: device.companyId,
          lat: device.lat,
          lon: device.lon,
        });

        setDeviceName(device.name ? device.name : '');

        setCompanyName(items[items.findIndex((item) => item.companyId === device.companyId)].name);
      })
      .then(() => {
        setLoading(false);
      });
  }, [deviceId]);

  const onSubmit = (data: any) => {
    setLoading(true);
    updateDevice(data)
      .catch((e) => {
        setError(`${e.message} \n ${e.response?.data?.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Box padding={2} component={Paper}>
      <Typography variant="h6" gutterBottom component="div" mb={2}>
        {companyName} - {deviceName} ({deviceId})
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button
              onClick={() => {
                navigate(`/admin/devices/${deviceId}/control`, { replace: false });
              }}
              variant="outlined"
            >
              Remote Control
            </Button>
            <Button
              onClick={() => {
                navigate(`/admin/devices/${deviceId}/data`, { replace: false });
              }}
              variant="outlined"
            >
              Data Analytics
            </Button>
          </Stack>
          <Controller
            name="deviceId"
            control={control}
            render={({ field }) => <TextField {...field} disabled label="ID" helperText="You can't change device ID" />}
          />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Serial No." helperText="Enter the device's Serial Number." />
            )}
          />
          <Controller
            name="companyId"
            control={control}
            render={({ field }) => (
              <FormControl required>
                <CompanySelect controlRenderProps={field} inputLabel="Location" />
                <FormHelperText>Choose a location where the device would be installed.</FormHelperText>
              </FormControl>
            )}
          />
          <Controller name="lat" control={control} render={({ field }) => <TextField {...field} label="Latitude" />} />
          <Controller name="lon" control={control} render={({ field }) => <TextField {...field} label="Longitude" />} />
        </Stack>
        <Stack direction="row" marginTop={2}>
          <Button type="submit" variant="contained" loading={isSubmitting}>
            Update
          </Button>
        </Stack>
      </form>
      {error && (
        <Stack py={2}>
          <Alert severity="error">
            <AlertTitle>サーバーエラー</AlertTitle>
            {error}
          </Alert>
        </Stack>
      )}
    </Box>
  );
};

export default Page;
