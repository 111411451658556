import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { updateDevice, findDevice, Api, updateDeviceLocalSettings } from '../../../libs/api';
import { DeviceContext } from '../../../contexts/device';
import { CompanyContext } from '../../../contexts/company';
import CompanySelect from '../../../components/CompanySelect';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type FormType = Api.Device.PatchLocalRequest;
type Item = Api.Company.Type;
let deviceReported: Api.Device.FindResponse;

const Page: React.FC = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const navigate = useNavigate();
  const { refresh } = React.useContext(DeviceContext);
  const { companies } = React.useContext(CompanyContext);
  const [loading, setLoading] = React.useState(true);
  const [deviceName, setDeviceName] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [error, setError] = React.useState<string>();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormType>({
    mode: 'onBlur',
    defaultValues: {
      numberOfWaterHeaters: '',
    },
  });
  const items: Item[] = [];
  for (let item of companies.values()) {
    items.push(item);
  }

  const extractChangedData = async (props: Api.Device.PatchLocalRequest): Promise<Api.Device.PatchLocalRequest> =>
    Object.keys(props).reduce((acc, key) => {
      const _acc: any = acc;
      if (
        (deviceReported[key as keyof typeof deviceReported] !== props[key as keyof typeof props] &&
          props[key as keyof typeof props] !== undefined) ||
        key === 'deviceId'
      ) {
        _acc[key] = props[key as keyof typeof props];
      }
      return _acc;
    }, {});

  React.useEffect(() => {
    if (!deviceId) return;
    findDevice({ deviceId })
      .then((device) => {
        deviceReported = device;

        reset({
          deviceId: device.deviceId,
          numberOfWaterHeaters: device.numberOfWaterHeaters ?? '',
        });

        setDeviceName(device.name ? device.name : '');

        setCompanyName(items[items.findIndex((item) => item.companyId === device.companyId)]?.name);
      })
      .then(() => {
        setLoading(false);
      });
  }, [deviceId]);

  const onSubmit = (data: Api.Device.DeviceLocalType) =>
    extractChangedData(data).then((data) => {
      setLoading(true);
      setError('');
      updateDeviceLocalSettings(data)
        .catch((e) => {
          setError(`${e.message} \n ${e.response?.data?.message}`);
        })
        .finally(() => {
          setLoading(false);
        });
    });

  return (
    <Box padding={2} component={Paper}>
      <Typography variant="h6" gutterBottom component="div" mb={2}>
        {companyName} - {deviceName} ({deviceId})
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Dummy</Typography>
          </AccordionSummary>
          <AccordionDetails></AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Water Heater Setup</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={4}>
              <Controller
                name="numberOfWaterHeaters"
                control={control}
                render={({ field }) => <TextField {...field} label="Number of Water Heaters" helperText="max: 123" />}
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Stack direction="row" marginTop={2}>
          <Button type="submit" variant="contained" disabled={loading} loading={isSubmitting}>
            Send change request to the device
          </Button>
          {loading && <CircularProgress />}
        </Stack>
      </form>
      {error && (
        <Stack py={2}>
          <Alert severity="error">
            <AlertTitle>Server Error</AlertTitle>
            {error}
          </Alert>
        </Stack>
      )}
    </Box>
  );
};

export default Page;
