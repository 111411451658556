import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { updateCompany, findCompany, Api } from '../../../libs/api';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';

import { CompanyContext } from '../../../contexts/company';

type FormType = Api.Company.PatchRequest;

const Page: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string>();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormType>();
  const { refresh } = React.useContext(CompanyContext);

  React.useEffect(() => {
    if (!companyId) return;
    findCompany({ companyId })
      .then((company) => {
        reset({
          companyId: companyId,
          name: company.name,
        });
        refresh();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companyId]);

  const onSubmit = (data: any) =>
    updateCompany(data)
      .then(() => {
        navigate('/admin/companies', { replace: true });
      })
      .catch((e) => {
        setError(e.message);
      });

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Box padding={2} component={Paper}>
      <Typography variant="h6" gutterBottom component="div" mb={2}>
        更新
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Controller
            name="companyId"
            control={control}
            render={({ field }) => <TextField disabled label="ID" {...field} />}
          />
          <Controller name="name" control={control} render={({ field }) => <TextField label="名称" {...field} />} />
          <Stack direction="row">
            <Button type="submit" variant="contained" loading={isSubmitting}>
              更新
            </Button>
          </Stack>
        </Stack>
      </form>
      {error && (
        <Stack py={2}>
          <Alert severity="error">
            <AlertTitle>サーバーエラー</AlertTitle>
            {error}
          </Alert>
        </Stack>
      )}
    </Box>
  );
};

export default Page;
