import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { createUser, Api } from '../../../libs/api';
import { roles } from '../../../consts/userRoles';
import CompanySelect from '../../../components/CompanySelect';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

type FormType = Api.User.PostRequest;

/**
 * ユーザーの新規作成フォームを描画します。
 * @returns
 */
const Page: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState<string>();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    // watch, // watchによってformの内容が変更された際にform全体の値を確認することができます。
  } = useForm<FormType>({
    mode: 'onBlur',
    defaultValues: {
      companies: [],
      role: '',
    },
  });

  // ポイント: useForm()からwatchを取得し、コンソールに出力するとformの変更内容をリアルタイムで確認できます。
  // 開発時に意図しない動作が確認された際には試してみてください
  // また、特定の値が変更されたことをトリガーに処理を行う際にも利用できます。
  // console.log(watch());

  const onSubmit = (data: FormType) =>
    createUser(data)
      .then(() => {
        navigate('/admin/users', { replace: true });
      })
      .catch((e) => {
        setError(e.message);
      });

  return (
    <Box padding={2} component={Paper}>
      <Typography variant="h6" gutterBottom component="div" mb={2}>
        Add new user
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextField
                required
                helperText="Enter email address of this user."
                label="email"
                type="email"
                {...field}
              />
            )}
          />
          <Controller
            name="companies"
            control={control}
            render={({ field }) => (
              <FormControl required>
                <CompanySelect controlRenderProps={field} multiple inputLabel="Locations" required />
                <FormHelperText>Choose Locations that this user will be able to access</FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <FormControl required>
                <InputLabel id="user-role-label">Role</InputLabel>
                <Select {...field} labelId="user-role-label" label="Role">
                  {Object.entries(roles).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Choose user role.</FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name="temporaryPassword"
            control={control}
            rules={{
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/,
                message: 'Please combine alphanumeric symbols with more than 8 characters.',
              },
            }}
            render={({ field }) => (
              <TextField
                error={!!errors.temporaryPassword}
                helperText={
                  errors.temporaryPassword?.message ||
                  'Please enter the password that this user will use to log in for the first time.'
                }
                required
                label="Initial password"
                type="password"
                {...field}
              />
            )}
          />

          <Stack direction="row">
            <Button type="submit" variant="contained" loading={isSubmitting}>
              Create
            </Button>
          </Stack>
        </Stack>
      </form>
      {error && (
        <Stack py={2}>
          <Alert severity="error">
            <AlertTitle>Server Error</AlertTitle>
            {error}
          </Alert>
        </Stack>
      )}
    </Box>
  );
};

export default Page;
