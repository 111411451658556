import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { createCompany, Api } from '../../../libs/api';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { CompanyContext } from '../../../contexts/company';

type FormType = Api.Company.PostRequest;
const Page: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState<string>();
  const { refresh } = React.useContext(CompanyContext);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormType>();

  const onSubmit = (data: FormType) =>
    createCompany(data)
      .then(() => {
        refresh().then(() => {
          navigate('/admin/companies', { replace: true });
        });
      })
      .catch((e) => {
        setError(e.message);
      });

  return (
    <Box padding={2} component={Paper}>
      <Typography variant="h6" gutterBottom component="div" mb={2}>
        Add new location
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => <TextField required label="Location name" {...field} />}
          />
          <Stack direction="row">
            <Button type="submit" variant="contained" loading={isSubmitting}>
              Create
            </Button>
          </Stack>
        </Stack>
      </form>
      {error && (
        <Stack py={2}>
          <Alert severity="error">
            <AlertTitle>サーバーエラー</AlertTitle>
            {error}
          </Alert>
        </Stack>
      )}
    </Box>
  );
};

export default Page;
