import React from 'react';

import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import { DeviceContext } from '../contexts/device';
import { CompanyContext } from '../contexts/company';

import { Api } from '../libs/api';

type Item = Api.Device.Type;

export type DeviceSelectProps = {
  onChange: (deviceId: string) => void;
  value: string | null;
};
const Component: React.FC<DeviceSelectProps> = (props) => {
  const { onChange, value } = props;
  const { devices, loading } = React.useContext(DeviceContext);
  const { companies } = React.useContext(CompanyContext);

  const handleChange: SelectProps<string>['onChange'] = (e) => {
    onChange(e.target.value);
  };

  const items: Item[] = [];
  for (let [_, value] of devices) {
    items.push(value);
  }

  type ItemCompany = Api.Company.Type;
  const itemsCompany: ItemCompany[] = [];
  for (let item of companies.values()) {
    itemsCompany.push(item);
  }

  return (
    <FormControl>
      <InputLabel id="device-select-label">Device</InputLabel>
      <Select
        value={value ?? ''}
        onChange={handleChange}
        disabled={loading}
        labelId="device-select-label"
        label="Device"
        autoWidth
      >
        {items.map((device) => (
          <MenuItem key={device.deviceId} value={device.deviceId}>
            {itemsCompany[itemsCompany.findIndex((item) => item.companyId === device.companyId)]?.name} - {device.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Component;
