import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { MainNavItems, AdminNavItems, SignOutItem } from '../components/Navbar';

const mdTheme = createTheme({
  palette: {
    primary: {
      main: '#cf000e',
    },
    background: {
      default: '#f3f4f5',
      paper: '#ffffff',
    },
    text: {
      primary: '#4c5768',
    },
  },
});

const DashboardContent: React.FC<{
  isAdmin: boolean;
  userName: string;
  tenantName: string;
}> = (props) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            <MenuItem key="mainNavItems" onClick={handleCloseNavMenu}>
              <MainNavItems />
            </MenuItem>

            {props.isAdmin && (
              <MenuItem key="adminNavItems" onClick={handleCloseNavMenu}>
                <AdminNavItems />
              </MenuItem>
            )}
            <MenuItem key="logoutNavItems" onClick={handleCloseNavMenu}>
              <SignOutItem />
            </MenuItem>
            <MenuItem key="account">
              {props.userName} ({props.isAdmin ? 'Admin' : 'User'})
            </MenuItem>
          </Menu>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            BMS - {props.tenantName}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Outlet />
        </Container>
      </Box>
    </ThemeProvider>
  );
};

const Dashboard: React.FC<{
  isAdmin: boolean;
  userName: string;
  tenantName: string;
}> = (props) => {
  return <DashboardContent isAdmin={props.isAdmin} userName={props.userName} tenantName={props.tenantName} />;
};

export default Dashboard;
