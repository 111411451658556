import React from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-date-fns';
import { ja } from 'date-fns/locale';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import { getChartData } from '../../libs/api';
import { MetricType } from '../../shared/types/api/metrics';

ChartJS.register(...registerables, ChartDataLabels, AnnotationPlugin);

type Props = {
  deviceId: string;
  from: Date;
  to: Date;
  chartType: MetricType;
};
const Component: React.FC<Props> = (props) => {
  const { deviceId, from, to, chartType } = props;
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<any>();
  React.useEffect(() => {
    setLoading(true);
    getChartData({
      deviceId,
      from: from.toISOString(),
      to: to.toISOString(),
      type: chartType,
    })
      .then((data: any) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [deviceId, from, to]);

  if (loading) {
    return (
      <Stack alignItems="center" padding={4}>
        <CircularProgress />
      </Stack>
    );
  }

  if (data == null) {
    return (
      <Stack alignItems="center" padding={4}>
        Empty
      </Stack>
    );
  }
  //console.log(JSON.stringify(data));

  const colorTable = [
    {
      borderColor: '#e60049',
      backgroundColor: '#e60049AA',
    },
    {
      borderColor: '#0bb4ff',
      backgroundColor: '#0bb4ffAA',
    },
    {
      borderColor: '#50e991',
      backgroundColor: '#50e991AA',
    },
    {
      borderColor: '#e6d800',
      backgroundColor: '#e6d800AA',
    },
    {
      borderColor: '#9b19f5',
      backgroundColor: '#9b19f5AA',
    },
    {
      borderColor: '#ffa300',
      backgroundColor: '#ffa300AA',
    },
    {
      borderColor: '#dc0ab4',
      backgroundColor: '#dc0ab4AA',
    },
    {
      borderColor: '#b3d4ff',
      backgroundColor: '#b3d4ffAA',
    },
    {
      borderColor: '#00bfa0',
      backgroundColor: '#00bfa0AA',
    },
  ];

  /**
   * 
  const master = {
    title: 'Chart.js Line Chart',
    time: [
      '2021-08-02T09:30:22.710731+09:00',
      '2021-08-02T11:10:22.710731+09:00',
      '2021-08-02T13:00:22.710731+09:00',
      '2021-08-02T15:00:22.710731+09:00',
      '2021-08-02T18:30:22.710731+09:00',
      '2021-08-02T19:50:22.710731+09:00',
    ],
    data: {
      T1: [1, 2, 3, 4, 5, 6],
      T2: [1, 21, 3, 4, 5, 6],
      T3: [1, 0, 13, 14, 15, 16],
    },
  };
   */
  const master = data;

  const datasets = [];
  const masterdata = master.data as { [index: string]: number[] };
  let i: number = 0;
  for (let key in masterdata) {
    i = i % colorTable.length;
    datasets.push({
      label: key,
      data: masterdata[key],
      borderColor: colorTable[i].borderColor,
      backgroundColor: colorTable[i++].backgroundColor,
    });
  }
  //console.log(datasets);

  const chartjsdata = {
    labels: master.time,
    datasets: datasets,
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        text: data.title,
        position: 'bottom',
      },
      legend: {
        display: true,
        position: 'bottom',
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          tooltipFormat: 'yyyy/MM/dd hh:mm',
          displayFormats: {
            hour: 'yy/MM/dd HH:mm',
          },
          unit: 'hour',
        },
        adapters: {
          date: {
            locale: ja,
          },
        },
      },
      y: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: '℃',
        },
      },
    },
  };

  return (
    <Stack height={400}>
      <Line datasetIdKey="timestamp" options={options} data={chartjsdata} />
    </Stack>
  );
};

export default Component;
