import React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  to: string;
};
const Component: React.FC<Props> = (props) => {
  const { to } = props;

  return (
    <Fab
      style={{
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
      }}
      color="primary"
      aria-label="add"
      component={RouterLink}
      to={to}
    >
      <AddIcon />
    </Fab>
  );
};

export default Component;
