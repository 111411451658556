import * as React from 'react';

import { getUsers, Api } from '../../../libs/api';
import { Link } from 'react-router-dom';

import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

import Fab from '../../../components/Link/Fab';

type ItemType = Api.User.GetResponse;

const Page: React.FC = () => {
  const [users, setUsers] = React.useState<ItemType>([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    getUsers()
      .then((users) => {
        setUsers(users);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <TableContainer component={Paper}>
        {loading && <LinearProgress />}
        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.username} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Link to={`/admin/users/${user.username}`}>{user.username}</Link>
                </TableCell>
                <TableCell>{user.role}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab to="/admin/users/new" />
    </Container>
  );
};
export default Page;
