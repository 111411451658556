import React from 'react';

import { Api, getCompanies } from '../libs/api';

type Company = Api.Company.Type;

type CompanyContextValueType = {
  companies: Map<string, Company>;
  loading: boolean;
};

type CompanyContextDispatchType = {
  refresh: () => Promise<void>;
};

/**
 * Company情報はページ横断的に利用されるのでアプリを立ち上げた時に取得してメモリ上に保存します
 */
export const CompanyContext = React.createContext<CompanyContextValueType & CompanyContextDispatchType>({
  companies: new Map(),
  loading: true,
  refresh: () => Promise.resolve(),
});

export const CompanyProvider: React.FC = (props) => {
  const { children } = props;

  const [value, setValue] = React.useState<CompanyContextValueType>({
    companies: new Map(),
    loading: true,
  });

  // Companyを追加した時にrefreshする必要があるのでrefresh関数を用意しておきます
  const refresh = () =>
    getCompanies().then((data) => {
      const companyMap = data.reduce((acc, cur) => {
        acc.set(cur.companyId, cur);
        return acc;
      }, new Map());
      setValue({
        companies: companyMap,
        loading: false,
      });
    });

  React.useEffect(() => {
    refresh();
  }, []);

  return (
    <CompanyContext.Provider
      value={{
        ...value,
        refresh,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};
