import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { updateUser, getUser, Api } from '../../../libs/api';
import { roles } from '../../../consts/userRoles';
import CompanySelect from '../../../components/CompanySelect';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

type FormType = Api.User.PatchRequest;

const Page: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormType>({
    mode: 'onBlur',
    defaultValues: {
      companies: [],
      role: '',
    },
  });

  React.useEffect(() => {
    if (!username) return;
    getUser({ username })
      .then((user) => {
        reset({
          username: user.username,
          companies: user.companies,
          role: user.role,
        });
        setLoaded(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [username]);

  const onSubmit = (data: any) =>
    updateUser(data)
      .then(() => {
        navigate('/admin/users', { replace: true });
      })
      .catch((e) => {
        setError(e.message);
      });

  if (loading || !loaded) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Box padding={2} component={Paper}>
      <Typography variant="h6" gutterBottom component="div" mb={2}>
        ユーザー更新
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Controller
            name="username"
            control={control}
            render={({ field }) => <TextField disabled label="email" type="email" {...field} />}
          />
          <Controller
            name="companies"
            control={control}
            render={({ field }) => (
              <FormControl required>
                <CompanySelect controlRenderProps={field} multiple inputLabel="閲覧できる施設" required />
                <FormHelperText>このユーザーが閲覧できる施設を選択してください</FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <FormControl required>
                <InputLabel id="user-role-label">ロール</InputLabel>
                <Select {...field} label="ロール" required labelId="user-role-label">
                  {Object.entries(roles).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>このユーザーがBmsで操作できる権限をロールで指定してください</FormHelperText>
              </FormControl>
            )}
          />
          <Stack direction="row">
            <Button type="submit" variant="contained" loading={isSubmitting}>
              更新
            </Button>
          </Stack>
        </Stack>
      </form>
      {error && (
        <Stack py={2}>
          <Alert severity="error">
            <AlertTitle>サーバーエラー</AlertTitle>
            {error}
          </Alert>
        </Stack>
      )}
    </Box>
  );
};

export default Page;
