import React from 'react';
import ReactDOM from 'react-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { UserProvider } from './contexts/user';
import { CompanyProvider } from './contexts/company';
import { DeviceProvider } from './contexts/device';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Authenticator
      children={() => (
        // アプリ全体で利用する可能性があるマスタについては最初に全てContext経由で取得するようにします
        <UserProvider>
          <CompanyProvider>
            <DeviceProvider>
              <App />
            </DeviceProvider>
          </CompanyProvider>
        </UserProvider>
      )}
      variation="modal"
    ></Authenticator>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
