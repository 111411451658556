import * as React from 'react';

import { getDevices, Api } from '../../../libs/api';
import { Link } from 'react-router-dom';

import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';

import Fab from '../../../components/Link/Fab';
import { CompanyContext } from '../../../contexts/company';

type ItemType = Api.Device.GetResponse;

const Page: React.FC = () => {
  const [items, setItems] = React.useState<ItemType>([]);
  const [loading, setLoading] = React.useState(true);
  const { companies } = React.useContext(CompanyContext);
  React.useEffect(() => {
    getDevices()
      .then((data) => {
        setItems(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <TableContainer component={Paper}>
        {loading && <LinearProgress />}
        <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Location</TableCell>
              <TableCell>Device Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              .sort((a, b) => (companies.get(a.companyId)?.name! > companies.get(b.companyId)?.name! ? 1 : -1))
              .map((item) => (
                <TableRow key={item.deviceId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{companies.get(item.companyId)?.name}</TableCell>
                  <TableCell>
                    {' '}
                    <Link color="inherit" to={`/admin/devices/${item.deviceId}`}>
                      {item.name}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab to="/admin/devices/new" />
    </Container>
  );
};
export default Page;
