/**
 * 以下の変数について環境変数もしくは.env または .env.localにて定義してから実行して下さい。
 */

const { REACT_APP_API_URL_BASE, REACT_APP_AUTH_USER_POOL_ID, REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID } = process.env;

if (!REACT_APP_API_URL_BASE) throw new Error('REACT_APP_API_URL_BASE is required to run/build.');
if (!REACT_APP_AUTH_USER_POOL_ID) throw new Error('REACT_APP_AUTH_USER_POOL_ID is required to run/build.');
if (!REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID)
  throw new Error('REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID is required to run/build.');

export const API_URL_BASE = REACT_APP_API_URL_BASE;
export const AUTH_USER_POOL_ID = REACT_APP_AUTH_USER_POOL_ID;
export const AUTH_USER_POOL_WEB_CLIENT_ID = REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID;
