import React from 'react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

import { AUTH_USER_POOL_ID, AUTH_USER_POOL_WEB_CLIENT_ID } from './consts/env';

import { UserContext } from './contexts/user';

import AppPage from './pages/app';
import DashboardPage from './pages/dashboard';
import AdminUsersIndexPage from './pages/admin/users';
import AdminUsersDetailPage from './pages/admin/users/edit';
import AdminUsersNewPage from './pages/admin/users/new';
import AdminDevicesIndexPage from './pages/admin/devices';
import AdminDevicesDetailPage from './pages/admin/devices/edit';
import AdminDevicesDataPage from './pages/admin/devices/data';
import AdminDevicesControlPage from './pages/admin/devices/control';
import AdminDevicesNewPage from './pages/admin/devices/new';
import AdminCompaniesIndexPage from './pages/admin/companies';
import AdminCompaniesDetailPage from './pages/admin/companies/edit';
import AdminCompaniesNewPage from './pages/admin/companies/new';

import './App.css';

Amplify.configure({
  Auth: {
    userPoolId: AUTH_USER_POOL_ID,
    userPoolWebClientId: AUTH_USER_POOL_WEB_CLIENT_ID,
  },
});

function App() {
  const { user } = React.useContext(UserContext);

  if (!user) {
    // user情報をAPI経由で取得中の場合(=null)の時にはProgressBarを表示する
    return <LinearProgress />;
  }

  // roleがadminの場合は表示可能な画面が変わるのでこのようにする
  const isAdmin = user.role === 'admin';

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <AppPage isAdmin={isAdmin} userName={user.username} tenantName={user.tenantname ? user.tenantname : ''} />
          }
        >
          {/* 
              app.tsxの中の<Outlet />がURLに合わせてelementで指定された要素と入れ替わります
              https://reactrouter.com/docs/en/v6/getting-started/tutorial#nested-routes
            */}
          <Route index element={<DashboardPage />} />
          {isAdmin && (
            <>
              <Route path="admin/users" element={<AdminUsersIndexPage />} />
              <Route path="admin/users/new" element={<AdminUsersNewPage />} />
              <Route path="admin/users/:username" element={<AdminUsersDetailPage />} />
              <Route path="admin/devices" element={<AdminDevicesIndexPage />} />
              <Route path="admin/devices/new" element={<AdminDevicesNewPage />} />
              <Route path="admin/devices/:deviceId" element={<AdminDevicesDetailPage />} />
              <Route path="admin/devices/:deviceId/data" element={<AdminDevicesDataPage />} />
              <Route path="admin/devices/:deviceId/control" element={<AdminDevicesControlPage />} />
              <Route path="admin/companies" element={<AdminCompaniesIndexPage />} />
              <Route path="admin/companies/new" element={<AdminCompaniesNewPage />} />
              <Route path="admin/companies/:companyId" element={<AdminCompaniesDetailPage />} />
            </>
          )}
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
